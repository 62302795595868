import React from 'react';

export default function Docker() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXLink="http://www.w3.org/1999/xlink"
      width="120"
      height="60"
    >
      <defs>
        <clipPath id="A">
          <path d="M76 2v46H54v23H35.58l-.206 2c-1.15 12.53 1.036 24.088 6.063 33.97l1.688 3.03c1 1.817 2.2 3.523 3.438 5.188s1.686 2.583 2.47 3.688C62.32 133.8 82.13 141 105 141c50.648 0 93.633-22.438 112.656-72.844C231.153 69.54 244.1 66.08 250 54.563c-9.4-5.424-21.478-3.685-28.437-.187L240 2l-72 46h-23V2z" />
        </clipPath>
      </defs>
      <g transform="matrix(.184659 0 0 .184659 3.070472 31.698131)">
        <path
          d="M467.82 28.24l-.042-25.558-.127-45.657M493.505-23.3L467.778 2.68l25.854 25.642m-172.347-25.3c.124-5.48-1.47-11.08-4.697-15.614-4.65-6.53-12.18-11-20.438-11.086-.55 0-1.096 0-1.65.042-3.265.202-6.427.655-9.225 1.82-18.4 7.656-21.66 33.208-6.136 44.77 13.445 10 33.002 5.924 39.775-9.35 1.43-3.228 2.37-7.297 2.37-10.58v-46.207M445.436-20.63c-3.8-2.462-8.255-3.35-12.652-3.343-.477 0-.967.024-1.396.042-12.713.547-24.5 9.53-24.5 26.954 0 20.6 22.088 31.718 38.6 22.003m74.135-2.243l39.056-33.47s-.95-1.797-1.566-2.623c-5.257-7.056-13.3-10.58-21.75-10.58-20.9 0-36.582 27.037-15.74 46.673 1.443 1.36 3.425 2.425 5.628 3.343 7.647 3.188 18.117 2.927 23.78-1.27m-185-48.663c-.593.01-1.182.074-1.777.085-15.855.3-27.286 16.76-23.823 32.032 3.375 14.883 19.634 23.696 33.682 18.83 16.36-4.3 23.03-24.9 14.048-38.802-4.913-7.6-13.24-12.313-22.13-12.144zm240.726.508c-6.42.017-12.673 2.266-17.307 6.6-7.308 6.03-8.894 13.647-8.8 23.273l.212 21.877"
          fill="none"
          stroke-width="10.5"
          stroke-linejoin="round"
          stroke="#394d54"
          stroke-linecap="round"
        />
        <path
          d="M147.488-39.268h22.866v23.375h11.562c5.34 0 10.83-.95 15.887-2.664 2.484-.842 5.273-2.015 7.724-3.5-3.228-4.214-4.876-9.536-5.36-14.78-.66-7.134.78-16.42 5.6-22.004l2.404-2.78 2.864 2.302c7.2 5.794 13.276 13.9 14.345 23.118 8.683-2.554 18.878-1.95 26.53 2.467l3.14 1.812-1.653 3.226C246.933-16.053 233.4-12.14 220.17-12.833c-19.796 49.31-62.897 72.653-115.156 72.653-27 0-51.77-10.093-65.876-34.047-.827-1.488-1.535-3.044-2.286-4.572C32.084 10.657 30.5-.894 31.574-12.436l.322-3.457H51.45v-23.375h22.866v-22.866h45.732V-85h27.44v45.732"
          fill="#394d54"
        />
        <g transform="translate(0 -85)" clip-path="url(#A)">
          <g id="B">
            <g id="C" transform="translate(0 -22.866)">
              <path
                d="M123.86 3.8h19.818v19.817H123.86z"
                fill="#00acd3"
              />
              <path
                d="M123.86 26.676h19.818v19.818H123.86z"
                fill="#20c2ef"
              />
              <path
                id="D"
                d="M126.292 21.977V5.46m2.972 16.516V5.46m3.002 16.516V5.46m3.003 16.516V5.46m3.003 16.516V5.46m2.97 16.516V5.46"
                stroke-width="1.56"
                stroke="#394d54"
              />
              <use xlinkHref="#D" y="22.866" />
            </g>
            <use
              xlinkHref="#C"
              transform="matrix(1 0 0 -1 22.866 4.572651)"
            />
          </g>
          <use xlinkHref="#B" x="-91.464" y="45.732" />
          <use xlinkHref="#B" x="-45.732" y="45.732" />
          <use xlinkHref="#B" y="45.732" />
          <path
            d="M221.57 54.38c1.533-11.915-7.384-21.275-12.914-25.718-6.373 7.368-7.363 26.678 2.635 34.807-5.58 4.956-17.337 9.448-29.376 9.448H34C32.83 85.484 34 146 34 146h217l-.987-91.424c-9.4-5.424-21.484-3.694-28.443-.197"
            fill="#17b5eb"
          />
          <path d="M34 89v57h217V89" fill-opacity=".17" />
          <path
            d="M111.237 140.9c-13.54-6.425-20.972-15.16-25.107-24.694L45 118l21 28 45.237-5.1"
            fill="#d4edf1"
          />
          <path
            d="M222.5 53.938v.03c-20.86 26.9-50.783 50.38-82.906 62.72-28.655 11.008-53.638 11.06-70.875 2.22-1.856-1.048-3.676-2.212-5.5-3.312-12.637-8.832-19.754-23.44-19.156-42.687H34V146h217V50h-25z"
            fill-opacity=".085"
          />
        </g>
        <path
          d="M45.625 32.03c14.165.775 29.282.914 42.47-3.22"
          fill="none"
          stroke-width="3.4"
          stroke="#394d54"
          stroke-linecap="round"
        />
        <path
          d="M102.17 21.96a5.47 5.47 0 0 1-5.467 5.467 5.47 5.47 0 0 1-5.468-5.467 5.47 5.47 0 0 1 5.468-5.468 5.47 5.47 0 0 1 5.467 5.468z"
          fill="#d4edf1"
        />
        <path
          d="M98.12 18.308a1.6 1.6 0 0 0 .798 2.981c.605 0 1.13-.336 1.402-.832.192.462.298.97.298 1.502 0 2.162-1.753 3.915-3.916 3.915s-3.916-1.753-3.916-3.915a3.92 3.92 0 0 1 3.916-3.917 3.91 3.91 0 0 1 1.418.265zM0 5.162h254.327c-5.538-1.405-17.52-3.302-15.545-10.56-10.07 11.652-34.353 8.174-40.48 2.43-6.825 9.898-46.555 6.136-49.326-1.575-8.556 10.04-35.067 10.04-43.623 0-2.772 7.7-42.5 11.473-49.327 1.575-6.128 5.746-30.4 9.223-40.48-2.43C17.522 1.86 5.54 3.758 0 5.162"
          fill="#394d54"
        />
      </g>
    </svg>
  );
}
