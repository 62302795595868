import React from 'react';

export default function Angular() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="120" height="60">
      <path
        d="M37.356 37.3V23.773h1.74l8.27 10.893V23.773h1.654V37.3h-1.74L39 26.3v11h-1.654zm25.137-.537a10.05 10.05 0 0 1-3.523.623c-4.898 0-7.348-2.342-7.348-7.047 0-4.447 2.363-6.682 7.1-6.682 1.354 0 2.62.193 3.78.56v1.504c-1.16-.45-2.363-.666-3.588-.666-3.695 0-5.543 1.762-5.543 5.242 0 3.76 1.826 5.63 5.457 5.63.58 0 1.225-.086 1.934-.236v-4.533h1.74v5.607zm2.793-4.684v-8.314h1.74v8.314c0 2.6 1.3 3.9 3.9 3.9 2.578 0 3.9-1.3 3.9-3.9v-8.314h1.74v8.314c0 3.545-1.87 5.328-5.63 5.328s-5.63-1.783-5.63-5.328zM81.4 23.773V35.9h7.1v1.418h-8.83V23.773h1.74zm9.604 13.535h-1.826L95.9 22l6.725 15.318h-1.934l-1.74-4.254h-4.47l.473-1.418h3.416l-2.535-6.123L91 37.318zm13.492 0V23.773h5.78c2.578 0 3.867 1.074 3.867 3.244 0 1.762-1.268 3.072-3.78 3.9l4.64 6.38h-2.3l-4.297-6.08V30.1c2.578-.408 3.9-1.396 3.9-2.986 0-1.246-.7-1.87-2.148-1.87h-3.867V37.3h-1.783z"
        fill="#b52e31"
      />
      <path
        d="M5 20.164l13.857-4.94 14.223 4.855-2.3 18.326L18.857 45l-11.73-6.5L5 20.164z"
        fill="#e23237"
      />
      <path
        d="M33.08 20.078l-14.223-4.855V45l11.924-6.596 2.3-18.326z"
        fill="#b52e31"
      />
      <path
        d="M18.88 18.703L10.242 37.9l3.223-.064 1.74-4.34h7.713l1.9 4.383 3.072.064-9.002-19.25zm.02 6.166l2.922 6.102h-5.48l2.558-6.1z"
        fill="#fff"
      />
    </svg>
  );
}
